import { FC } from 'react';
import { Link } from 'react-router-dom';
import { instagram_icon, telegram_icon } from '../../icons';
import s from './styles.module.css';

export const SocialLinks: FC = () => (
  <div className={s.socials}>
    <Link to='https://t.me/nstatt00' target='_blank'>
      {telegram_icon}
    </Link>
    <Link to='https://instagram.com/ns.tattoo.kiev?igshid=NTc4MTIwNjQ2YQ==' target='_blank'>
      {instagram_icon}
    </Link>
  </div>
);
