import React, { FC } from "react";
import { Link } from "react-router-dom";
import { instagram_icon, telegram_icon } from "../../icons";
import { CertificatesPropsType } from "./types";
import s from "./styles.module.css";

export const Certificates: FC<CertificatesPropsType> = ({ texts, lang }) => (
  <div className={s.main}>
    <div className={s.cert_block}>
      <div className={s.img}>
        <img
          loading="lazy"
          src="/certificats/cert_front.webp"
          alt="certificats front"
          height="300px"
          width="100%"
        />
      </div>
      <div className={s.text}>
        <div className={s.text_item}>
          {texts[0][lang]}
          <br />
          {texts[1][lang]}
        </div>
        <div className={s.text_item}>{texts[2][lang]}</div>
      </div>
    </div>
    <div className={s.cert_block}>
      <div className={s.img}>
        <img
          loading="lazy"
          src="/certificats/cert_back.webp"
          alt="certificats back"
          height="300px"
          width="100%"
        />
      </div>
      <div className={s.text}>
        <div className={s.text_item}>{texts[3][lang]}</div>
        <div className={s.socials}>
          <div className={s.text_item}>{texts[4][lang]}</div>
          <div className={s.socials_item_shell}>
            <Link to="https://t.me/nstatt00" target="_blank">
              {telegram_icon}
            </Link>
            <Link
              to="https://instagram.com/ns.tattoo.kiev?igshid=NTc4MTIwNjQ2YQ=="
              target="_blank"
            >
              {instagram_icon}
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);
