import { FC } from "react";
import { Link } from "react-router-dom";
import { MapContainerPropsType, StaffCardType } from "./types";
import s from "./styles.module.css";

export const MapContainer: FC<MapContainerPropsType> = ({
  cards,
  background,
  lang,
}) => (
  <div className={s.main} style={{ background: background ?? "#fff" }}>
    <div className={s.container}>
      {cards.map((item: StaffCardType) => (
        <Link
          key={item.id}
          className={s.item}
          to={`/member/${item.name["en"]}`}
        >
          <img
            loading="lazy"
            className={s.image}
            src={item.image}
            alt={item.name[lang]}
            height="auto"
            width="300px"
          />
          <div className={s.name}>{item.name[lang]}</div>
        </Link>
      ))}
    </div>
  </div>
);
