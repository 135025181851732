import { FC } from "react";
import { Link } from "react-router-dom";
import { instagram_icon, telegram_icon } from "../../icons";
import { InfoSectionPropsType } from "./types";
import s from "./styles.module.css";

export const InfoSection: FC<InfoSectionPropsType> = ({
  lang,
  data: {
    location_title,
    location_street,
    location_city,
    wh_title,
    wh_description,
    wh_period,
    appointment,
  },
}) => (
  <div className={s.main}>
    <div className={s.info}>
      <h2 className={s.title}>{location_title[lang]}</h2>
      <p className={s.info_text}>{location_street[lang]}</p>
      <p className={s.info_text}>{location_city[lang]}</p>
    </div>
    <div className={s.info}>
      <h2 className={s.title}>{wh_title[lang]}</h2>
      <p className={s.info_text}>{wh_description[lang]}</p>
      <p className={s.info_text}>{wh_period[lang]}</p>
    </div>
    <div className={s.info}>
      <h2 className={s.title}>{appointment[lang]}</h2>
      <div className={s.icons}>
        <Link to="https://t.me/nstatt00" target="_blank">
          {telegram_icon}
        </Link>
        <Link
          to="https://instagram.com/ns.tattoo.kiev?igshid=NTc4MTIwNjQ2YQ=="
          target="_blank"
        >
          {instagram_icon}
        </Link>
      </div>
    </div>
  </div>
);
