import { FC } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { darkMapStyles } from "../../constants";
import s from "./styles.module.css";

export const Gmap: FC = () => {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY || "";

  const lat = 50.4405763;
  const lng = 30.5199557;
  const center = { lat, lng };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey,
  });

  if (!isLoaded) {
    return (
      <div className={s.main}>
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={s.main}>
      <GoogleMap
        mapContainerClassName={s.container}
        center={center}
        zoom={18}
        options={{
          styles: darkMapStyles,
        }}
      >
        <Marker
          position={center}
          // Use a default red marker icon
          icon="http://maps.google.com/mapfiles/ms/icons/red-dot.png"
        />
      </GoogleMap>
    </div>
  );
};
