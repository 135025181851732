import { FC } from 'react';
import { animateScroll } from 'react-scroll';
import { arrow_up_circle_icon } from '../../icons';
import { ScrollTopBtnPropsType } from './types';
import s from './styles.module.css';

export const ScrollTopBtn: FC<ScrollTopBtnPropsType> = ({ show }) =>
  show && (
    <div
      className={s.main}
      onClick={() => animateScroll.scrollToTop({ duration: 1000, smooth: true, to: 'top' })}
    >
      {arrow_up_circle_icon}
    </div>
  );
