import { FC, useState } from "react";
import { arrow_down_icon, arrow_up_icon } from "../../icons";
import { TextSectionPointType, TextSectionPropsType } from "./types";
import s from "./styles.module.css";

export const TextSection: FC<TextSectionPropsType> = ({
  title,
  text,
  final,
  lang,
}) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);
  return (
    <div className={s.main}>
      {title && (
        <div onClick={toggleOpen} className={s.title_container}>
          <h3 className={s.title}>{title[lang]}</h3>
          {text.length > 0 && (
            <div className={s.open}>
              {open ? arrow_up_icon : arrow_down_icon}
            </div>
          )}
        </div>
      )}
      {(open || !title) &&
        text.map((paragraph: TextSectionPointType, index: number) => (
          <p key={paragraph.id + index} className={s.paragraph}>
            {paragraph.main && (
              <strong className={s.paragraph_main}>
                {paragraph.main[lang]}
              </strong>
            )}
            {paragraph.text[lang]}
          </p>
        ))}
      {final && <p>{final[lang]}</p>}
    </div>
  );
};
