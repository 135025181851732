import { FC, useState } from "react";
import { scroller } from "react-scroll";
import { Link } from "react-router-dom";
import { DropDown } from "../DropDown";
import { SelectLang } from "../SelectLang";
import { burger_icon, close_icon_for_navbar } from "../../icons";
import { navDropdown } from "../../constants";
import { NavItemType, NavbarPropsType } from "./types";
import s from "./styles.module.css";

export const Navbar: FC<NavbarPropsType> = ({
  tabs,
  currentLang,
  toggleLang,
  isScrolled,
}) => {
  const [burgerStatus, setBurgerStatus] = useState<boolean>(false);

  const closeNav = () => setBurgerStatus(false);

  const onNavItemClick = (link: string) => {
    scroller.scrollTo(link, {
      duration: 2000,
      delay: 100,
      smooth: true,
      offset: -60,
    });
    closeNav();
  };

  const dropdowTitle = currentLang === "en" ? "services" : "послуги";

  const Tabs: FC<{ isMobile?: boolean }> = ({ isMobile }) =>
    tabs.map((tab: NavItemType) =>
      tab.id !== 6 ? (
        <Link
          key={s.item + tab.id}
          className={s.item}
          to={tab.link}
          onClick={() => onNavItemClick(tab.link)}
        >
          {tab.name[currentLang]}
        </Link>
      ) : (
        <div
          key={s.item + tab.id}
          className={isMobile ? s.container_mobile_list_sources : ""}
        >
          <DropDown
            tabs={navDropdown}
            title={dropdowTitle}
            isMobile={isMobile}
            closeNav={closeNav}
            lang={currentLang}
          />
        </div>
      )
    );

  return (
    <div className={s.back}>
      <div className={s.main + " " + (isScrolled ? s.filled : "")}>
        <div className={s.container}>
          <Tabs />
        </div>
        <SelectLang currentLang={currentLang} toggleLang={toggleLang} />
      </div>

      <div className={s.container_mobile}>
        <div className={s.all_icons}>
          <div
            onClick={() => setBurgerStatus(!burgerStatus)}
            className={s.burger_icon}
          >
            {burgerStatus ? close_icon_for_navbar : burger_icon}
          </div>
          <SelectLang currentLang={currentLang} toggleLang={toggleLang} />
        </div>
        {burgerStatus && (
          <div
            className={s.container_mobile_list}
            style={{ background: "#181b18" }}
          >
            <Tabs isMobile />
          </div>
        )}
      </div>
    </div>
  );
};
