import { FC, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { arrow_up_icon } from '../../icons';
import { scroller } from 'react-scroll';
import { NavItemType } from '../Navbar/types';
import { DropDownPropsType } from './types';
import s from './styles.module.css';

export const DropDown: FC<DropDownPropsType> = ({ tabs, title, isMobile, closeNav, lang }) => {
  const [showItems, setShowItems] = useState(false);
  const [positionLeft, setPositionLeft] = useState(0);

  const ref = useRef<HTMLInputElement>(null);

  const clickX = () => {
    if (!ref.current) {
      return;
    }
    setPositionLeft(ref.current.offsetLeft);
    setShowItems(!showItems);
  };

  const onItemClick = (item: NavItemType) => {
    scroller.scrollTo(item.link, {
      duration: 2000,
      delay: 100,
      offset: -50,
      smooth: true
    });
    setShowItems(false);
    closeNav();
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowItems(false);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={s.main} ref={ref}>
      <div className={s.item_container} onClick={clickX}>
        <div className={s.item}>{title}</div>
        <div className={showItems ? '' : s.arrow_revert}>{arrow_up_icon}</div>
      </div>
      {showItems && (
        <div
          className={s.container}
          style={
            isMobile
              ? { top: 'auto', left: 0, width: '100%', justifyContent: 'start', marginTop: '20px' }
              : {
                  left: positionLeft - 70,
                  display: 'flex',
                  width: '250px',
                  borderRadius: '15px'
                }
          }
        >
          {tabs.map((item: NavItemType) => (
            <Link
              className={s.item}
              key={item.id}
              to={item.link}
              onClick={() => onItemClick(item)}
              style={isMobile ? { marginTop: '20px' } : {}}
            >
              {item.name[lang]}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};
