import { FC } from "react";
import { Gmap } from "../Gmap";
import { MapPropsType } from "./types";
import s from "./styles.module.css";

export const Location: FC<MapPropsType> = ({ title, lang }) => (
  <div id={"#location"} className={s.main}>
    <div className={s.text}>{title[lang]}</div>
    {process.env.NODE_ENV === "development" ? "map should be here" : <Gmap />}
  </div>
);
