import { FC, memo, useEffect, useState } from "react";
import { Progress } from "../Progress/index";
import { isPc } from "../../tools";
import { VideoPropsType } from "./types";
import s from "./styles.module.css";

export const Video: FC<VideoPropsType> = memo(
  ({ name, poster, isResponsive, duration, onFinished }) => {
    // const [videoUrl, setVideoUrl] = useState<string>("");
    // const [downloadProgress, setDownloadProgress] = useState<number>(0);
    const [isSupported, setIsSupported] = useState<boolean>(false);

    // const extension = isPc() ? "webm" : "mp4";

    function isVideoSupported() {
      const video = document.createElement("video");
      return !!video.canPlayType;
    }

    // const onDownloadFinished = (data: string) => {
    //   const blob = new Blob([data], { type: `video/${extension}` });
    //   const url = URL.createObjectURL(blob);
    //   setVideoUrl(url);

    //   if (duration && onFinished) {
    //     const tm = setTimeout(() => {
    //       onFinished();
    //       clearTimeout(tm);
    //     }, duration);
    //   }
    // };

    // // using fetch
    // const fetchData = () => {
    //   const url = window.location.origin + "/" + name + "." + extension;

    //   fetch(url)
    //     .then((response: any) => {
    //       if (!response.ok) {
    //         throw new Error("Network response was not ok");
    //       }

    //       const contentLength = response.headers.get("Content-Length");
    //       if (!contentLength) {
    //         throw new Error("Content-Length header is missing");
    //       }

    //       const total = parseInt(contentLength, 10);
    //       let loaded = 0;

    //       const reader = response.body.getReader();

    //       return new ReadableStream({
    //         start(controller) {
    //           function pump() {
    //             reader
    //               .read()
    //               .then(({ done, value }: { done: any; value: any }) => {
    //                 if (done) {
    //                   controller.close();
    //                   return;
    //                 }

    //                 loaded += value.byteLength;
    //                 const percentage = Math.round((loaded * 100) / total);
    //                 // Update download progress here
    //                 setDownloadProgress(percentage);

    //                 controller.enqueue(value);
    //                 pump();
    //               });
    //           }

    //           pump();
    //         },
    //       });
    //     })
    //     .then((stream) => new Response(stream))
    //     .then((response) => response.arrayBuffer())
    //     .then((data: any) => {
    //       onDownloadFinished(data);
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching the video:", error);
    //     });
    // };

    // // using axios
    // // const fetchData = () => {
    // //   const url = window.location.origin + "/" + name + "." + extension;

    // //   axios({
    // //     method: "get",
    // //     url: url,
    // //     responseType: "arraybuffer",
    // //     onDownloadProgress: function (progressEvent) {
    // //       if (progressEvent && progressEvent.total) {
    // //         const percentage = Math.round(
    // //           (progressEvent.loaded * 100) / progressEvent.total
    // //         );
    // //         setDownloadProgress(percentage);
    // //       }
    // //     },
    // //   })
    // //     .then((response) => {
    // //       onDownloadFinished(response.data);
    // //     })
    // //     .catch((error) => {
    // //       console.error("Error fetching the video:", error);
    // //     });
    // // };

    // const fetchStorageData = (data: string) => {
    //   setDownloadProgress(100);
    //   onDownloadFinished(data);
    // };

    // useEffect(() => {
    //   if (isVideoSupported()) {
    //     setIsSupported(true);
    //     const storageVideoData = localStorage.getItem(`videoUrl_${name}`);
    //     if (storageVideoData) {
    //       fetchStorageData(storageVideoData);
    //     } else {
    //       fetchData();
    //     }
    //   } else {
    //     onFinished && onFinished();
    //   }
    // }, [name]);

    // if (isSupported && downloadProgress !== 100) {
    //   return <Progress progress={downloadProgress} />;
    // } else {
    //   localStorage.setItem(`videoUrl_${name}`, videoUrl);
    // }

    useEffect(() => {
      setIsSupported(isVideoSupported());
      if (duration) {
        const tm = setTimeout(() => {
          onFinished && onFinished();
          clearTimeout(tm);
        }, duration);
      }
    }, [name]);

    return (
      <div className={isResponsive ? s.responsive_container : s.container}>
        {!isSupported ? (
          <img src={poster} alt="Poster" />
        ) : (
          <video
            className={s.video}
            loop={!duration}
            width={"100vw"}
            height={"100vh"}
            preload={"auto"}
            playsInline
            autoPlay
            muted
          >
            <source src={`/${name}.mp4`} type={`video/mp4`} />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
    );
  }
);
