import { FC } from "react";
import { Link } from "react-router-dom";
import { instagram_icon } from "../../icons";
import { ShopPropsType } from "./types";
import s from "./styles.module.css";

export const Shop: FC<ShopPropsType> = ({ description, lang }) => (
  <div id={"#tattooshop"} className={s.main}>
    <img
      loading="lazy"
      src="/shop_background.png"
      className={s.image}
      alt="shop"
      height="auto"
      width="100vw"
    />

    <div className={s.description}>
      <p>{description[lang]}</p>
      <Link
        className={s.icon}
        to="https://instagram.com/ns_tattoo.shop?igshid=NTc4MTIwNjQ2YQ=="
        target="_blank"
      >
        {instagram_icon}
      </Link>
    </div>
  </div>
);
