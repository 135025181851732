import { FC } from "react";
import { SelectLangPropsType } from "./types";
import s from "./styles.module.css";

export const SelectLang: FC<SelectLangPropsType> = ({
  currentLang,
  toggleLang,
}) => (
  <div className={s.main} onClick={toggleLang}>
    <div
      className={s.lang + " " + `${currentLang === "ua" ? s.lang_active : ""}`}
    >
      UA
    </div>
    <div
      className={s.lang + " " + `${currentLang === "en" ? s.lang_active : ""}`}
    >
      EN
    </div>
  </div>
);
