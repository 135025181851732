import { FC } from "react";
import { InterimScreenPropsType } from "./types";
import s from "./styles.module.css";

export const InterimScreen: FC<InterimScreenPropsType> = ({
  title,
  background_url,
  height,
  tag,
}) => (
  <div
    id={tag ?? title}
    className={s.main}
    style={{ height: height ? `${height}vh` : "auto" }}
  >
    <img
      loading="lazy"
      src={background_url}
      className={s.image}
      alt={background_url}
      height="auto"
      width="100vw"
    />
    {title && <div className={s.title}>{title}</div>}
  </div>
);
