import { FC, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { getSliderShowParams } from "../../tools";
import { staffCards } from "../../constants";
import {
  instagram_icon,
  left_arrow_icon,
  right_arrow_icon,
  telegram_icon,
} from "../../icons";
import { StaffCardType } from "../MapContainer/types";
import {
  AppointmentPropsType,
  DescriptionPropsType,
  GalleryPreviewPropsType,
  GalleryPropsType,
  IntroPropsType,
  StaffPagePropsType,
} from "./types";
import s from "./styles.module.css";

const Intro: FC<IntroPropsType> = ({ url, name, lang }) => (
  <div
    style={{ backgroundImage: `url(${url})`, maxHeight: url ? 500 : 200 }}
    className={s.intro}
  >
    <div className={s.name}>{name[lang]}</div>
  </div>
);

const Description: FC<DescriptionPropsType> = ({ description, lang }) => (
  <div className={s.description}>
    <p>{description[lang]}</p>
  </div>
);

const Appointment: FC<AppointmentPropsType> = ({ links }) => (
  <div className={s.appointment}>
    <div className={s.appointment_text}>Запис:</div>
    <div>
      {links.map((link: string, index: number) => (
        <Link
          key={link + index}
          to={link}
          target="_blank"
          className={s.appointment_link}
        >
          {index === 0 ? telegram_icon : instagram_icon}
        </Link>
      ))}
    </div>
  </div>
);

const GalleryPreview: FC<GalleryPreviewPropsType> = ({
  id,
  photo,
  setPhoto,
  show,
}) =>
  show && (
    <div className={s.preview} onClick={() => setPhoto(id)}>
      <div
        className={s.preview_image}
        style={{ backgroundImage: `url(${photo})` }}
      />
    </div>
  );

const Gallery: FC<GalleryPropsType> = ({ photos, setPhoto }) => {
  return (
    photos.length && (
      <div className={s.gallery}>
        <Slide
          autoplay={false}
          slidesToShow={getSliderShowParams()[0]}
          slidesToScroll={getSliderShowParams()[1]}
          cssClass={s.slide}
          transitionDuration={600}
          prevArrow={<div className={s.prev}>{left_arrow_icon}</div>}
          nextArrow={<div className={s.next}>{right_arrow_icon}</div>}
        >
          {photos.map((photo: string, index: number) => (
            <div key={photo + index} onClick={() => setPhoto(index)}>
              <img
                loading="lazy"
                src={photo}
                className={s.gallery_photo}
                alt={photo}
                height="auto"
                width="100%"
              />
            </div>
          ))}
        </Slide>
      </div>
    )
  );
};

export const StaffPage: FC<StaffPagePropsType> = ({ lang }) => {
  const [selectedPhoto, setSelectedPhoto] = useState<number>(-1);
  const location = useLocation();
  const navigate = useNavigate();

  const name = location.pathname.split("/")[2];
  const id = staffCards
    .map((item: StaffCardType) => item.name["en"])
    .indexOf(name);

  const setPhoto = (index: number) => {
    const id = index === selectedPhoto ? -1 : index;
    setSelectedPhoto(id);
  };

  useEffect(() => {
    if (id === -1) {
      navigate("/");
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    id !== -1 && (
      <div className={s.main}>
        <Intro
          url={staffCards[id].intro}
          name={staffCards[id].name}
          lang={lang}
        />
        <Description description={staffCards[id].description} lang={lang} />
        <Appointment
          links={[staffCards[id].telegram, staffCards[id].instagram]}
        />
        <GalleryPreview
          id={selectedPhoto}
          photo={staffCards[id].work_process_images[selectedPhoto]}
          setPhoto={setPhoto}
          show={selectedPhoto !== -1}
        />
        <Gallery
          photos={staffCards[id].work_process_images}
          setPhoto={setPhoto}
        />
        <Link className={s.back_btn} to={"/#staff"}>
          назад
        </Link>
      </div>
    )
  );
};
