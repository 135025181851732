import { FC, useCallback, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Page404 } from "./components/Page404";
import { StaffPage } from "./components/StaffPage";
import { InterimScreen } from "./components/InterimScreen";
import { TextSection } from "./components/TextSection";
import { Navbar } from "./components/Navbar";
import { Video } from "./components/Video";
import { InfoSection } from "./components/InfoSection";
import { MapContainer } from "./components/MapContainer";
import { Shop } from "./components/Shop";
import { Contacts } from "./components/Contacts";
import { Location } from "./components/Location";
import { Certificates } from "./components/Certificates";
import { SocialLinks } from "./components/SocialLinks";
import { ScrollTopBtn } from "./components/ScrollTopBtn";
import {
  certificates_texts,
  certs_info_title,
  info_section_data,
  map_title,
  rent_info,
  rules_guidelines,
  rules_guidelines_title,
  rules_rent_title,
  rules_researches,
  rules_researches_title,
  shop_description,
  staffCards,
  studio_info,
  study_info,
  study_info_intro,
  study_info_title,
  tabs,
} from "./constants";
import { LanguageType } from "./types";
import "./App.css";

export const App: FC = () => {
  const [isPreviewVisible, setIsPreviewVisible] = useState<boolean>(true);
  const [currentLang, setCurrentLang] = useState<"ua" | "en">("ua");
  const [isScrolled] = useState<boolean>(true);

  const toggleLang = useCallback(() => {
    const lang: LanguageType = currentLang === "ua" ? "en" : "ua";
    setCurrentLang(lang);
    localStorage.setItem("lang", lang);
  }, [currentLang]);

  const onPreviewFinished = useCallback(
    () => setIsPreviewVisible(false),
    [isPreviewVisible]
  );

  useEffect(() => {
    const lang = localStorage.getItem("lang");

    if (!lang) return;
    if (lang === currentLang) return;
    if (lang !== "en" && lang !== "ua") return;
    setCurrentLang(lang);
  }, [currentLang]);

  const HomeRoute = (
    <>
      <Navbar
        tabs={tabs}
        currentLang={currentLang}
        toggleLang={toggleLang}
        isScrolled={isScrolled}
      />
      <Video name={"intro"} poster={"/intro.png"} isResponsive />
      <InfoSection data={info_section_data} lang={currentLang} />
      <InterimScreen
        title={currentLang === "en" ? "artists" : "татумайстри"}
        background_url="/masters.webp"
        tag="#staff"
      />
      <MapContainer
        cards={staffCards}
        background={"#0d0908"}
        lang={currentLang}
      />
      <InterimScreen
        title={
          currentLang === "en" ? "Important informaiton" : "Важлива інформація"
        }
        background_url="/important_info.webp"
        tag="#importantinfo"
      />
      <TextSection
        title={rules_guidelines_title}
        text={rules_guidelines}
        lang={currentLang}
      />
      <TextSection
        title={rules_researches_title}
        text={rules_researches}
        lang={currentLang}
      />
      <Location title={map_title} lang={currentLang} />
      <Shop description={shop_description} lang={currentLang} />
      <InterimScreen
        title={
          currentLang === "en" ? "Gift certificates" : "Подарункові сертифікати"
        }
        background_url="/certificats.webp"
        tag="#certificates"
      />
      <TextSection title={certs_info_title} text={[]} lang={currentLang} />
      <Certificates texts={certificates_texts} lang={currentLang} />
      <InterimScreen
        title={currentLang === "en" ? "Rent" : "Оренда"}
        background_url="/rent.webp"
        tag="#workspacerent"
      />
      <TextSection
        title={rules_rent_title}
        text={rent_info}
        lang={currentLang}
      />
      <InterimScreen
        title={currentLang === "en" ? "Education" : "Навчання"}
        background_url="/study.webp"
        tag="#study"
      />
      <TextSection text={study_info_intro} lang={currentLang} />
      <TextSection
        title={study_info_title}
        text={study_info}
        lang={currentLang}
      />
      <SocialLinks />
      <TextSection text={studio_info} lang={currentLang} />
      <Contacts />
      <ScrollTopBtn show={isScrolled} />
    </>
  );

  const InitialScreen = isPreviewVisible ? (
    <Video
      name={"load"}
      poster={"/load.png"}
      duration={4000}
      onFinished={onPreviewFinished}
    />
  ) : (
    HomeRoute
  );

  return (
    <div className="App" id={"#"}>
      <Routes>
        <Route path="/" element={InitialScreen} />
        <Route path="/member/:id" element={<StaffPage lang={currentLang} />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </div>
  );
};
