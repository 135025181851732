import { CertificatesTextsType } from "./components/Certificates/types";
import { SocialMediaType } from "./components/Contacts/types";
import { InfoSectionDataType } from "./components/InfoSection/types";
import { StaffCardType } from "./components/MapContainer/types";
import { NavItemType } from "./components/Navbar/types";
import { TextSectionPointType } from "./components/TextSection/types";
import {
  instagram_icon,
  telegram_icon,
  viber_icon,
  whatsapp_icon,
} from "./icons";
import { LanguageType } from "./types";

export const tabs: NavItemType[] = [
  {
    id: 0,
    name: {
      ua: "студія",
      en: "studio",
    },
    link: "#",
  },
  {
    id: 1,
    name: {
      ua: "татумайстри",
      en: "tattoo artists",
    },
    link: "#staff",
  },
  {
    id: 2,
    name: {
      ua: "важлива інформація",
      en: "important information",
    },
    link: "#importantinfo",
  },
  {
    id: 3,
    name: {
      ua: "локація",
      en: "location",
    },
    link: "#location",
  },
  {
    id: 4,
    name: {
      ua: "татумагазин",
      en: "tattoo shop",
    },
    link: "#tattooshop",
  },
  {
    id: 5,
    name: {
      ua: "контакти",
      en: "contacts",
    },
    link: "#contacts",
  },
  {
    id: 6,
    name: {
      ua: "послуги",
      en: "services",
    },
    link: "#certs",
  },
];

export const navDropdown: NavItemType[] = [
  {
    id: 0,
    name: { ua: "сертифікати", en: "certificates" },
    link: "#certificates",
  },
  {
    id: 1,
    name: {
      ua: "оренда робочого місця",
      en: "workplace rent",
    },
    link: "#workspacerent",
  },
  {
    id: 2,
    name: { ua: "навчання", en: "education" },
    link: "#study",
  },
];

export const staffCards: StaffCardType[] = [
  {
    id: 0,
    name: {
      en: "Dima",
      ua: "Дмитро",
    },
    image: "/staff/dmutro/logo_click.webp",
    intro: "/staff/dmutro/intro.webp",
    work_process_images: [
      "/staff/dmutro/works/dmutro1.webp",
      "/staff/dmutro/works/dmutro2.webp",
      "/staff/dmutro/works/dmutro3.webp",
      "/staff/dmutro/works/dmutro4.webp",
      "/staff/dmutro/works/dmutro5.webp",
      "/staff/dmutro/works/dmutro6.webp",
      "/staff/dmutro/works/dmutro7.webp",
      "/staff/dmutro/works/dmutro8.webp",
      "/staff/dmutro/works/dmutro9.webp",
      "/staff/dmutro/works/dmutro10.webp",
    ],
    description: {
      en: "I began practicing tattoo art in 2016. My tattoo style defies precise definition, as I integrate the best elements from various styles that I consider to be outstanding. I employ VIP-shading and graphic techniques to create works of art on your skin through the use of shadows.",
      ua: `Я почав займатися тату з 2016 року. Мій стиль тату не піддається точному визначенню, тому що я об'єдную в ньому найкращі елементи з різних стилів, які я вважаю найвидатнішими. Я використовую техніку віп-шейпінгу та графіки, щоб створювати витвори мистецтва на вашій шкірі за допомогою тіней .Працюю чорними фарбами та темними відтінками, які допомагають створити щось стильне та повільне, що намагається. Записуйтесь до мене на консультацію, завжди буду радий допомогти вам втілити вашу ідею)`,
    },
    telegram: "https://t.me/montanaslap",
    instagram: "https://instagram.com/zabittttoo?igshid=MzRlODBiNWFlZA==",
  },
  {
    id: 1,
    name: {
      en: "Yulia",
      ua: "Юля",
    },
    image: "/staff/julia/logo_click.webp",
    intro: "/staff/julia/intro.webp",
    work_process_images: [],
    description: {
      en: "The path of learning tattooing began in 2023. With 14 years of experience in drawing, I have gained great confidence in the chosen direction. I feel excellent in graphic design and everything related to the Land of the Rising Sun. Skillfully intertwining modernity and old photos, engravings, and posters of Asia, I fearlessly experiment by combining seemingly impossible drawings in the chosen theme. I work in a monochromatic palette with clear contours. In my free time, I cultivate bonsai, exotic plants, hedonism, and my cat. I can provide consultation on your future body art in the studio.",
      ua: "Шлях навчання татуювання розпочався у 2023 році. За плечима 14 річний досвід роботи з малюнком, що дало велику впевненість у вибраному напрямку. Чудово себе почуваю у графіку і все, що пов'язано з країною сонця, що сходить. Уміло переплітаю сучасність і старі фото, гравюри, постери Азії. Не боюся експериментувати поєднуючи неможливі на перший погляд малюнки в обраній тематиці. Працюю в монохромній палітрі з чіткими контурами. У вільний час вирощую бонсаї, екзотичні рослини, гедонізм та свого кота. Проконсультую з майбутньої картини на тілі у студії.",
    },
    telegram: "https://t.me/sayvayy",
    instagram: "https://instagram.com/sayvay.gallery?igshid=MzRlODBiNWFlZA==",
  },
  {
    id: 2,
    name: {
      en: "Katia",
      ua: "Катя",
    },
    image: "/staff/katya/logo_click.webp",
    intro: "/staff/katya/intro.webp",
    work_process_images: [],
    description: {
      en: `Hello! I'm Katya, and my training began in 2022. I have been a practicing artist for a year now. I study as an artist and have six years of experience in academic drawing and painting, allowing me to create a personal sketch based on your preferences. The history of art has shaped my preferences, so floristics and ancient art are my main focus. I create works in the styles of graphics and watercolor shading. I work in black and white and with local colors. I look forward to a consultation via personal messages or in a cozy studio in the heart of Kyiv.`,
      ua: "Привіт ! Я Катя і моє навчання почалось в 2022 році. Вже рік я практикуючий майстер .  Навчаюсь на художника та маю шестирічний досвід роботи з академічним рисунком та живописом, що дає можливість розробити особистий  ескіз за Вашими побажаннями.  Історія мистецтв сформувала мої вподобання , тому флористика та античне мистецтво — мій основний напрям.  Виконую роботи в стилі - графіки та віпшейдінгу.  Працюю в чб та локальними кольорами.  Чекаю на консультацію в особисті повідомлення або в затишній студії в самому центрі Києва .",
    },
    telegram: "https://t.me/ltchk",
    instagram:
      "https://instagram.com/letchenko.kateryna?igshid=MzRlODBiNWFlZA==",
  },
  {
    id: 3,
    name: {
      en: "Kamila",
      ua: "Каміла",
    },
    image: "/staff/kamila/logo_click.webp",
    intro: "/staff/kamila/intro.webp",
    work_process_images: [
      "/staff/kamila/works/kamila1.webp",
      "/staff/kamila/works/kamila2.webp",
      "/staff/kamila/works/kamila3.webp",
      "/staff/kamila/works/kamila4.webp",
      "/staff/kamila/works/kamila5.webp",
      "/staff/kamila/works/kamila6.webp",
      "/staff/kamila/works/kamila7.webp",
      "/staff/kamila/works/kamila8.webp",
      "/staff/kamila/works/kamila9.webp",
      "/staff/kamila/works/kamila10.webp",
    ],
    description: {
      en: `Hello, everyone!

My name is Kamila, and I'm a tattoo artist with a twist. My professional journey began in 2019. But can we say "it began" for someone who has been passionate about creativity all their life?

The first sketches of a young artist can be found on the walls of my home. The invaluable creativity that laid the foundation for a tattoo artist with numerous positive reviews.

What sets me apart from others?

Hmm, how about the fact that I'll do better than you could imagine for yourself?

Among my works, you'll find not only precisely crafted anime-style tattoos but also delicate and light fine lines that will adorn even the most delicate body. A full box of minimalist works, each detail of which is clearly and gently embedded into the model's skin.

The body is like a canvas. And only the artist can give it meaning. You are the master who paints its soul, and I take care of the aesthetics of every inked detail from the outside.

Are you looking for an artist whose hands tremble only after seeing the result? Then, without hesitation, send your ideas, and let's start planning the details of the sketch ahead!`,
      ua: "Всім привіт! Мене звати Камілла і я тату-майстер з родзинкою. Мій професійний шлях бере початок ще з 2019 року та чи можна сказати таке про людину, яка горить творчістю все життя? Перші накиди молодого митця можна знайти на стінах мого будинку. Неоціненна творчість, яка дала початок тату-майстру з чисельними позитивними відгуками. Що відрізняє мене від інших? Хмм, а як щодо того, що я зроблю краще, аніж ви собі могли уявити? Серед моїх наробок ви знайдете не лише чітко вибудовані татуювання в стилі аніме, але й ніжні та легкі тоненькі лінії, які прикрасять навіть найтендітніше тіло. Повну скриньку мінімалістичних робіт, кожна деталь яких чітко та ніжно впинається у шкіру моделі. Тіло – як полотно. І тільки митець може надати йому сенсу. Ви – майстер, який розмальовує його душу, а я турбуюсь про естетику кожної набитої детальки ззовні. Шукаєте майстра, у якого руки тремтять тільки після побаченого результату? Тоді без вагань надсилайте свої ідеї і вперед продумувати деталі ескізу!",
    },
    telegram: "https://t.me/sssssssunsetlover",
    instagram: "https://instagram.com/milas.tattoo?igshid=MzRlODBiNWFlZA==",
  },
  {
    id: 4,
    name: {
      en: "Anton",
      ua: "Антон",
    },
    image: "/staff/anton/logo_click.webp",
    intro: "/staff/anton/intro.webp",
    work_process_images: [
      "/staff/anton/works/anton1.webp",
      "/staff/anton/works/anton2.webp",
      "/staff/anton/works/anton3.webp",
      "/staff/anton/works/anton4.webp",
      "/staff/anton/works/anton5.webp",
      "/staff/anton/works/anton6.webp",
      "/staff/anton/works/anton7.webp",
      "/staff/anton/works/anton8.webp",
    ],
    description: {
      en: `Hello! My name is Anton, and I'm a tattoo artist from Ukraine. My journey in the world of tattoos began in 2019, and since then, I have dedicated myself to the art of tattooing. I specialize in creating individual and unique enhancements for your body, always striving to transform your ideas into picturesque masterpieces on the skin.

My goal is to provide you with an excellent experience and quality results. I am always ready to listen to your desires and ideas, and then turn them into art on your skin.

I invite you to visit my tattoo studio, where we create a comfortable and friendly atmosphere. I feel great gratitude for the opportunity to create unique and meaningful tattoos for my clients.`,
      ua: "Привіт! Мене звати Антон, я татумайстер з України. Моя пригода у світі татуювань розпочалася у 2019 році, і з того часу я присвятив себе мистецтву тату. Спеціалізуюся у створенні індивідуальних та унікальних доповнень вашого тіла, завжди намагаюся перетворити ваші ідеї в живописні шедеври на шкірі.Моя мета - забезпечити вас відмінним досвідом та якісними результатами. Готовий завжди слухати ваші бажання та ідеї, а потім перетворити їх у мистецтво на вашій шкірі. Запрошую вас завітати до моєї тату студії, де ми створюємо комфортну та дружню атмосферу. Відчуваю велику вдячність за можливість створювати унікальні та значущі татуювання для моїх клієнтів.",
    },
    telegram: "https://t.me/bblbblblb",
    instagram: "https://instagram.com/sonnik.bzz.bzz?igshid=MzRlODBiNWFlZA==",
  },
];

export const darkMapStyles = [
  { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#263c3f" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#6b9a76" }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#38414e" }],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{ color: "#212a37" }],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9ca5b3" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#746855" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: "#1f2835" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#f3d19c" }],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: "#2f3948" }],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#17263c" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#515c6d" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [{ color: "#17263c" }],
  },
];

export const rules_guidelines_title: { [k in LanguageType]: string } = {
  ua: "ВАЖЛИВІ ВКАЗІВКИ ПРИЗНАЧЕННЯ",
  en: "IMPORTANT APPOINTMENT GUIDELINES",
};

export const rules_guidelines: TextSectionPointType[] = [
  {
    id: 0,
    main: {
      en: "Age Requirement:",
      ua: "Вікові вимоги:",
    },
    text: {
      en: " please note that you must be at least 18 years old at the time of your appointment. We strictly adhere to legal age restrictions for tattooing.",
      ua: " будь ласка, зверніть увагу, що вам має бути не менше 18 років на момент вашого призначення. Ми суворо дотримуємося законодавчих вікових обмежень для нанесення татуювань.",
    },
  },
  {
    id: 1,
    main: {
      en: "Timeliness:",
      ua: "Своєчасність:",
    },
    text: {
      en: " we kindly request that you arrive on time for your scheduled appointment. Punctuality ensures that we can provide you with the best possible service and avoid any delays.",
      ua: " ми просимо вас прибути вчасно на запланований прийом. Пунктуальність гарантує, що ми зможемо надати вам найкращий сервіс і уникнути затримок.",
    },
  },
  {
    id: 2,
    main: {
      en: "Solo Visits:",
      ua: "Індивідуальні візити:",
    },
    text: {
      en: " to maintain a focused and efficient environment, we kindly ask that you do not bring an entourage with you to your appointment. This allows both you and the artist to concentrate fully on creating your tattoo.",
      ua: " щоб підтримувати цілеспрямовану та ефективну обстановку, ми люб’язно просимо вас не брати з собою на зустріч супутніх осіб. Це дозволяє вам і художнику повністю зосередитися на створенні вашого татуювання.",
    },
  },
  {
    id: 3,
    main: {
      en: "Sobriety Policy:",
      ua: "Політика тверезості:",
    },
    text: {
      en: " for the safety of everyone involved and to ensure optimal tattooing conditions, we have a strict no-drugs policy on our premises. Please arrive sober and refrain from bringing any drugs onto the premises.",
      ua: " задля безпеки всіх учасників та для забезпечення оптимальних умов для татуювання ми дотримуємося суворої політики заборони вживання наркотиків у наших приміщеннях. Просимо приходити тверезими та не проносити будь-яких наркотиків на територію.",
    },
  },
  {
    id: 4,
    main: {
      en: "Hygiene:",
      ua: "Гігієна:",
    },
    text: {
      en: " we kindly request that you shower before your appointment to maintain cleanliness and a pleasant environment. Let’s ensure we all have a comfortable experience by being considerate of personal hygiene.",
      ua: " ми просимо вас прийняти душ перед прийомом, щоб підтримувати чистоту та приємне середовище. Давайте подбаємо про те, щоб нам усім було комфортно, уважно ставлячись до особистої гігієни.",
    },
  },
  {
    id: 5,
    main: {
      en: "Deposits:",
      ua: "Передплата:",
    },
    text: {
      en: " deposits are required to secure your appointment and are nonrefundable. We appreciate your understanding in this matter, as it helps us manage our schedule and ensure commitment from both parties.",
      ua: " Передплата необхідна для забезпечення вашого запису та не повертається. Ми цінуємо ваше розуміння в цьому питанні, оскільки це допомагає нам керувати нашим графіком і гарантувати прихильність обох сторін.",
    },
  },
  {
    id: 6,
    main: {
      en: "Artist’s Terms:",
      ua: "Умови виконавця:",
    },
    text: {
      en: " each artist at NS Studio may have their own specific terms and guidelines. We kindly ask that you carefully read and accept your artist’s terms, as they may vary from artist to artist.",
      ua: " кожен виконавець у NS Studio може мати власні особливі умови та правила. Ми просимо вас уважно прочитати та прийняти умови вашого виконавця, оскільки вони можуть відрізнятися від виконавця до виконавця.",
    },
  },
  {
    id: 7,
    main: {
      en: "Direct Communication:",
      ua: "Прямий зв’язок.",
    },
    text: {
      en: " for any inquiries or concerns regarding your tattoo, please communicate directly with your artist. They are the best source of information and can provide you with the necessary guidance.",
      ua: " З будь-якими запитаннями чи проблемами щодо вашого татуювання зв’яжіться безпосередньо зі своїм художником. Вони є найкращим джерелом інформації та можуть надати вам необхідні вказівки.",
    },
  },
  {
    id: 8,
    text: {
      en: "Thank you for taking the time to review these important guidelines. We appreciate your cooperation and look forward to providing you with an exceptional tattooing experience at NS Studio.",
      ua: "Дякуємо, що знайшли час переглянути ці важливі вказівки. Ми цінуємо вашу співпрацю та сподіваємося надати вам винятковий досвід татуювання в NS Studio.",
    },
  },
];

export const rules_researches_title: { [k in LanguageType]: string } = {
  en: "AFTERCARE",
  ua: "ДОГЛЯД",
};

export const rules_researches: TextSectionPointType[] = [
  {
    id: 0,
    main: {
      en: "Keep It Clean:",
      ua: "Тримайте в чистоті:",
    },

    text: {
      en: " gently wash your tattoo with mild, fragrance-free soap and warm water. Pat dry with a clean, soft cloth.",
      ua: " обережно промийте татуювання м’яким милом без ароматизаторів і теплою водою. Витріть насухо чистою м’якою тканиною.",
    },
  },
  {
    id: 1,
    main: {
      en: "Moisturize:",
      ua: "Зволоження:",
    },

    text: {
      en: " apply a thin layer of tattoo-friendly, unscented moisturizer to keep your skin hydrated and prevent itching.",
      ua: " нанесіть тонкий шар зволожуючого крему без запаху, який підходить для татуювань, щоб зберегти шкіру зволоженою та запобігти свербінню.",
    },
  },
  {
    id: 2,
    main: {
      en: "Avoid Sun Exposure:",
      ua: "Уникайте впливу сонця:",
    },

    text: {
      en: " shield your tattoo from direct sunlight and tanning beds. If exposed, use SPF 30+ sunblock after it’s healed.",
      ua: " захищайте татуювання від прямих сонячних променів і солярію. У разі впливу використовуйте сонцезахисний крем SPF 30+ після загоєння.",
    },
  },
  {
    id: 3,
    main: {
      en: "No Scratching or Picking:",
      ua: "Заборонено дряпати або колупати:",
    },

    text: {
      en: " resist the urge to scratch or pick at your tattoo to avoid damaging the healing process.",
      ua: " не піддавайтеся бажанню подряпати татуювання, щоб не зашкодити процесу загоєння.",
    },
  },
  {
    id: 4,
    main: {
      en: "Loose Clothing:",
      ua: "Вільний одяг:",
    },

    text: {
      en: " wear loose, breathable clothing to prevent friction and allow your tattoo to breathe.",
      ua: " носіть вільний, дихаючий одяг, щоб запобігти тертю та дозволити вашому татуюванню дихати.",
    },
  },
  {
    id: 5,
    main: {
      en: "Skip Swimming:",
      ua: "Пропустіть плавання:",
    },

    text: {
      en: " avoid pools, hot tubs, and baths until your tattoo is fully healed to prevent infection.",
      ua: " уникайте басейнів, гарячих ванн і ванн, доки ваше татуювання повністю не заживе, щоб запобігти інфекції.",
    },
  },
  {
    id: 6,
    main: {
      en: "Say No to Soaking:",
      ua: "Скажіть «Ні» намоченню:",
    },

    text: {
      en: " don’t soak your tattoo in water, including saunas and hot baths, during the healing phase.",
      ua: " не замочуйте татуювання у воді, включаючи сауни та гарячі ванни, під час фази загоєння.",
    },
  },
  {
    id: 7,
    main: {
      en: "Healing Time:",
      ua: "Час загоєння:",
    },

    text: {
      en: " healing usually takes about 2-4 weeks. Scabbing and peeling are normal parts of the process.",
      ua: " загоєння зазвичай займає близько 2-4 тижнів. Поява струпа та лущення є нормальними частинами процесу.",
    },
  },
  {
    id: 8,
    main: {
      en: "Listen to Your Artist:",
      ua: "Слухайте свого майстра:",
    },
    text: {
      en: " follow any additional aftercare instructions given by your tattoo artist.",
      ua: " дотримуйтесь будь-яких додаткових інструкцій щодо догляду, наданих вашим майстром татуювань.",
    },
  },
  {
    id: 9,
    main: {
      en: "Trust the Process:",
      ua: "Довіртеся процесу:",
    },

    text: {
      en: " every tattoo heals differently; trust your body’s natural healing process.",
      ua: " кожне татуювання заживає по-різному; довіртеся природному процесу одужання свого тіла.",
    },
  },
];

export const studio_info: TextSectionPointType[] = [
  {
    id: 0,
    text: {
      en: "NS Tattoo Studio was founded on August 8, 2023 with the goal of bringing together talented artists who have a wealth of experience in this incredible industry. We created this space to give them the opportunity to develop their art and have their own corner for creativity.",
      ua: "NS Tattoo Studio була заснована 8 серпня 2023 року з метою об’єднати в себе талановитих майстрів, які мають багатий досвід у цій неймовірній галузі. Ми створили цей простір, щоб надати їм можливість розвивати своє мистецтво та мати свій власний куточок для творчості.",
    },
  },
  {
    id: 1,
    text: {
      en: "Our team joined this mission with the aim of glorifying Ukrainian art and creativity at the international level. We strive to highlight the individuality of each craftsman and inspire creativity.",
      ua: "Наш колектив долучився до цієї місії з метою прославляти українське мистецтво та творчість на міжнародному рівні. Ми прагнемо висвітлювати індивідуальність кожного майстра та надихати на творчість.",
    },
  },
  {
    id: 2,
    text: {
      en: "We are open to cooperation with masters from all over the world. We invite foreign artists to join our studio, where each master and his works become part of the unforgettable history of Ukrainian art.",
      ua: "Ми відкриті для співпраці із майстрами з усього світу. Запрошуємо іноземних артистів приєднатися до нашої студії, де кожен майстер та його роботи стають частиною незабутньої історії українського мистецтва.",
    },
  },
  {
    id: 3,
    text: {
      en: "NS Studio is a great tool for those who want a professional-quality tattoo, and who are looking for a team and a space where you can develop your skills and personality.",
      ua: "NS Studio - чудовий інструмент для тих , хто хоче тату професійної якості, та хто шукає колектив і простір в якому можна розвивати свій скіл та особистість.",
    },
  },
];

export const rules_certs_title: { [k in LanguageType]: string } = {
  ua: "Подарункові Сертифікати NS Studio",
  en: "NS Studio Gift Certificates",
};

export const certs_info_title: { [k in LanguageType]: string } = {
  ua: "Приємно вас вітати у світі вираження мистетства на тілі! Наші сертифікати - це особливий спосіб подарувати вашим близьким незабутні спогади.",
  en: "It is a pleasure to welcome you to the world of art expression on the body! Our certificates are a special way to give your loved ones unforgettable memories.",
};

export const rules_rent_title: { [k in LanguageType]: string } = {
  en: "Welcome to NS Studio - your space for creativity and development!",
  ua: "Ласкаво просимо до NS Studio - вашого простору для творчості та розвитку!",
};

export const rent_info: TextSectionPointType[] = [
  {
    id: 0,
    text: {
      en: "Advantages of renting a workplace from us:",
      ua: "Переваги оренди робочого місця у нас:",
    },
  },
  {
    id: 1,
    main: {
      en: "Convenience and comfort.",
      ua: "Зручність і комфорт.",
    },

    text: {
      en: " You have access to stylish and comfortable furniture that creates an ergonomic work environment.",
      ua: " У вас є доступ до стильних та зручних меблів, які створюють ергономічне робоче середовище.",
    },
  },
  {
    id: 3,
    main: {
      en: "Location:",
      ua: "Розташування:",
    },

    text: {
      en: " We are located in the center of the capital, which makes our studio convenient for masters and clients.",
      ua: " Ми знаходимось у центрі столиці, що робить нашу студію зручною для майстрів та клієнтів.",
    },
  },
  {
    id: 3,
    main: {
      en: "Order and tidiness:",
      ua: "Порядок та охайність:",
    },

    text: {
      en: " Our cleaner always keeps the studio in perfect order so you can focus on your work.",
      ua: " Наша прибиральниця завжди тримає студію в ідеальному порядку, щоб ви могли фокусуватися на своїй роботі.",
    },
  },
  {
    id: 4,
    main: {
      en: "All necessary materials:",
      ua: "Всі необхідні матеріали:",
    },

    text: {
      en: " We provide all the necessary consumables to make your creative process as efficient as possible.",
      ua: " Ми надаємо всі необхідні розхідні матеріали, зоб ваш творчий процес був якнайбільш ефективним.",
    },
  },
  {
    id: 5,
    text: {
      en: "Our team creates ideal conditions for creativity, and the atmosphere in the studio is filled with inspiration and opportunities to develop your potential.",
      ua: "Наша команда створює ідеальні умови для творчості, а атмосфера в студії наповнена натхненням та можливостями для розвитку вашого потенціалу.",
    },
  },
  {
    id: 6,
    text: {
      en: "Enter NS Studio and enjoy a work environment that supports your productivity and success",
      ua: "Заходьте до NS Studio та насолоджуйтеся робочим середовищем, яке сприяє вашій продуктивності та успіху",
    },
  },
];

export const rules_study_title: { [k in LanguageType]: string } = {
  en: "We invite you to the exciting path of self-expression!",
  ua: "Запрошуємо вас на захоплюючий шлях самовиразності!",
};

export const study_info_title: { [k in LanguageType]: string } = {
  en: "IN OUR STUDIO YOU WILL FIND:",
  ua: "У НАШІЙ СТУДІЇ ВАС ОЧІКУЄ:",
};

export const study_info_intro: TextSectionPointType[] = [
  {
    id: 0,
    text: {
      en: "This is not a tattoo school, but a unique opportunity to get individual knowledge and skills from our experts.",
      ua: "Це не школа тату, а унікальна можливість отримати індивідуальні знання та майстерність від наших експертів.",
    },
  },
];

export const study_info: TextSectionPointType[] = [
  {
    id: 0,
    main: {
      en: "Selection of the master:",
      ua: "Вибір майстра:",
    },
    text: {
      en: " A personal approach to each is provided. You choose the one that suits you best.",
      ua: " Особистий підхід до кожного подавана. Ви вибираєте того, хто вам неабйльше підходить.",
    },
  },
  {
    id: 1,
    main: {
      en: "New features:",
      ua: "Нові можливості:",
    },

    text: {
      en: " Learn about current trends and techniques in this exciting industry.",
      ua: " Дізнайтеся про актуальні тенденції та техніки в цій захоплюючій галузі.",
    },
  },
  {
    id: 2,
    main: {
      en: "Mastery at the top:",
      ua: "Майстерність на вершині:",
    },
    text: {
      en: " Our professionals are ready to share their secrets and knowledge.",
      ua: " Наші професіонали готові поділитися своїми секретами та знаннями.",
    },
  },
  {
    id: 3,
    text: {
      en: "Come to our studio, where art comes alive on your skin. Choose your master and reveal your own creativity and uniqueness!",
      ua: "Приходьте до нас в студію, де мистетство стає живим на вашій шкірі. Оберіть свого майстра та розкрийте власну креативність та унікальність!",
    },
  },
  {
    id: 4,
    text: {
      en: "Join us today and learn more about how we can help you realize your ideas and desires.",
      ua: "Долучайтеся до нас сьогодні та дізнайтеся більше про те, як ми можемо допомогти вам реалізувати ваші ідеї та забажання.",
    },
  },
  {
    id: 5,
    text: {
      en: "Write to us and find out the details:",
      ua: "Напиши нам, та дійзнайся деталі:",
    },
  },
];

export const socials: SocialMediaType[] = [
  {
    id: 0,
    icon: instagram_icon,
    link: "https://instagram.com/ns.tattoo.kiev?igshid=NTc4MTIwNjQ2YQ==",
    text: "instagram",
  },
  {
    id: 1,
    icon: telegram_icon,
    link: "https://t.me/nstatt00",
    text: "telegram",
  },
  {
    id: 2,
    icon: whatsapp_icon,
    link: "https://wa.me/380984563711",
    text: "+380984563711",
  },
  {
    id: 3,
    icon: viber_icon,
    link: "viber://add?number=380984563711",
    text: "+380984563711",
  },
];

export const info_section_data: InfoSectionDataType = {
  location_title: {
    en: "Location",
    ua: "Локація",
  },
  location_street: {
    en: "Velika Vasylkivska 9/2",
    ua: "Велика Васильківська 9/2",
  },
  location_city: {
    en: "Kyiv",
    ua: "Київ",
  },
  wh_title: {
    en: "Hours of work",
    ua: "Години роботи",
  },
  wh_description: {
    en: "No days off",
    ua: "Без вихідних",
  },
  wh_period: {
    en: "9:00 - 22:00",
    ua: "9:00 - 22:00",
  },
  appointment: {
    en: "Appointment",
    ua: "Запис",
  },
};

export const shop_description: { [k in LanguageType]: string } = {
  en: "Welcome to our tattoo shop on Velyka Vasylivska Street, 9/2! We have a large selection of cartridges, tattoo machines, brushes and other tattoo products. Come and choose the best tools for your work!",
  ua: "Ласкаво просимо до нашого тату-магазину на вулиці Велика Василівська, 9/2! У нас великий вибір картріджів, татумашинок, батлів та інших товарів для татуажу. Завітайте та обирайте для вашої роботи найкращі інструменти!",
};

export const certificates_texts: CertificatesTextsType = [
  {
    en: "You can choose any master in our studio",
    ua: "Ви можете обрати будь-якого майстра в нашій студії",
  },
  {
    en: "Contacts for ordering",
    ua: "Контакти для замовлення",
  },
  {
    en: "The advantages of this approach is that there is an opportunity to think everything over, because the certificate is valid for one year, from the date of purchase",
    ua: "Переваги такого підходу в тому що є можливість все обдумати, адже сертифікат діє один рік, з дати покупки",
  },
  {
    en: "Own serial number of the certificate",
    ua: "Власний серійний номер сертифікату",
  },
  {
    en: "Order",
    ua: "Замовити",
  },
];

export const map_title: { [k in LanguageType]: string } = {
  en: "Location",
  ua: "Локація",
};
