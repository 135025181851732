import { FC } from "react";
import { Link } from "react-router-dom";
import { socials } from "../../constants";
import { SocialMediaType } from "./types";
import s from "./styles.module.css";

export const Contacts: FC = () => (
  <div id={"#contacts"} className={s.main}>
    {socials.map((item: SocialMediaType, index: number) => (
      <div className={s.item} key={item.id + index}>
        <Link className={s.icon} to={item.link} target="_blank">
          {item.icon}
        </Link>
        {item.text && <div className={s.text}>{item.text}</div>}
      </div>
    ))}
  </div>
);
